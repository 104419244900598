import { TrackingLinkConfig, TrackingConfig } from '../types'

export const getSeoZoneCtaTrackingConfig = (
  sourcePagePath: string,
  destinationPagePath: string,
  ctaValue: string,
  position: number
): TrackingLinkConfig => {
  const pageZone = 'SEO Zone'
  const subSection = undefined

  return trackingOutput({ sourcePagePath, destinationPagePath, pageZone, ctaValue, subSection, position })
}

// ##### WILL ERASE THIS WHEN FULLY TRANSITIONED TO NEW TILES
const trackingOutput = (ctaTrackingConfig: TrackingConfig): TrackingLinkConfig => {
  const { destinationPagePath, ctaValue, mpvId, productKey, subSection, sourcePagePath, position, pageZone } = ctaTrackingConfig
  const { experimentId, experimentKey, variationId, variationKey } = ctaTrackingConfig
  const parsedDestinationPagePath = removeQueryParamsFrom(destinationPagePath)
  const composedCtaValue = subSection ? `${subSection}:${ctaValue}` : ctaValue
  const eventDetail = `${sourcePagePath};${parsedDestinationPagePath};${pageZone};${composedCtaValue}`
  const navigationDetail = `${composedCtaValue}:${position}`

  const eventName = 'Navigation Clicked'
  const experimentProperties =
    experimentId && variationId
      ? {
          experimentId: experimentId.toString(),
          experimentName: experimentKey,
          variationId: variationId.toString(),
          variationName: variationKey,
        }
      : {}

  return {
    eventName,
    properties: {
      category: 'Home Page',
      label: `Content:${pageZone}`,
      eventDetail,
      navigationDetail: pageZone === 'Hero' ? ctaValue : navigationDetail,
      product_id: mpvId,
      core_product_id: productKey,
      ...experimentProperties,
    },
    linkAttributes: {
      'data-section': `Content:${pageZone}`,
      'data-position': position,
      'data-translation': composedCtaValue,
    },
  }
}

export const removeQueryParamsFrom = (pagePath: string): string => {
  if (typeof window !== 'undefined') {
    return new URL(pagePath, window.location.origin).pathname
  }

  return pagePath
}
