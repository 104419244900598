import { BoundedContent, Box, Column, GridContainer, H2, Row, Typography, useScreenClass } from '@vp/swan'
import { getSeoZoneCtaTrackingConfig } from '../utils/TrackingConfigs'
import { MarkdownWithTrackableLinks } from './MarkdownWithTrackableLinks'
import { SeoTiles } from './SeoTiles'
import { useTrackingContext } from '../hooks/TrackingContext'

export const SeoZone = ({ header, description, tiles }: any) => {
  const { sourcePagePath } = useTrackingContext()
  const screenClass = useScreenClass()
  if (!header || !description) {
    return <></>
  }
  const textSize = screenClass === 'lg' ? 3 : 2
  const startTrackingPositionForTiles = 2

  return (
    <Box style={{ backgroundColor: '#F8F8F8' }} pt={{ md: 10, lg: 11 }} pb={{ xs: 8, md: 11 }}>
      <BoundedContent>
        <GridContainer>
          <Row>
            <Column
              span={12}
              spanSm={6}
              spanMd={6}
              spanLg={4}
              spanXl={4}
              offsetLg={1}
              offsetXl={1}
              pr={screenClass === 'sm' ? '7' : undefined}
            >
              <H2 fontWeight='bold'>{header}</H2>
              <Typography mt='7' fontSize={textSize}>
                <MarkdownWithTrackableLinks
                  html={description}
                  getTrackingInfo={(destinationPagePath, ctaValue) =>
                    getSeoZoneCtaTrackingConfig(sourcePagePath, destinationPagePath, ctaValue, 1)}
                />
              </Typography>
            </Column>
            <Column span={12} spanSm={6} spanMd={6} spanLg={4} spanXl={4} offsetLg={2} offsetXl={2} pl='7' pt={{ xs: 6, sm: 2 }}>

              <SeoTiles zoneTiles={tiles} fontSize={textSize} startTrackingPositionForTiles={startTrackingPositionForTiles} />

            </Column>
          </Row>
        </GridContainer>
      </BoundedContent>
    </Box>
  )
}
