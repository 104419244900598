import { TrackingProvider } from './hooks/TrackingContext'
import { SeoZone } from './components/SeoZone'

export const App = (props: any) => {
  const { seoZoneHeader, seoZoneTiles, seoZoneDescription } = props.pageData
  return (
    <TrackingProvider>
      <SeoZone header={seoZoneHeader} description={seoZoneDescription} tiles={seoZoneTiles} />
    </TrackingProvider>
  )
}
