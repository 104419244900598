import React, { useContext } from 'react'

const TrackingContext = React.createContext()
const useTrackingContext = () => {
  const tracking = useContext(TrackingContext)
  return tracking
}

const TrackingProvider = props => {
  return (
    <TrackingContext.Provider
      value={{
        sourcePagePath: props.homepageSlug,
      }}
    >
      {props.children}
    </TrackingContext.Provider>
  )
}

export { TrackingProvider, useTrackingContext }
