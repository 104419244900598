import parse, { attributesToProps, domToReact } from 'html-react-parser'
import { createOnClickTrackingHandler } from '../utils/OnClickTracking'
import { Link } from '@vp/swan'

export const MarkdownWithTrackableLinks = ({ html, getTrackingInfo }: any) => {
  const options = {
    replace: domNode => {
      const props = attributesToProps(domNode.attribs)

      if (domNode.name === 'a' && props.href) {
        const ctaValue = domNode.children.length > 0 && domNode.children[0].type === 'text' ? domNode.children[0].data : ''
        const trackingConfiguration = getTrackingInfo(props.href, ctaValue)
        const linkTrackingAttributes = trackingConfiguration ? trackingConfiguration.linkAttributes : {}

        return (
          <Link href={props.href} onClick={createOnClickTrackingHandler(trackingConfiguration, props.href)} {...linkTrackingAttributes}>
            {domToReact(domNode.children)}
          </Link>
        )
      }
    },
  }
  return html ? parse(html, options) : null
}
