import { Box, H3, Typography } from '@vp/swan'
import { MarkdownWithTrackableLinks } from './MarkdownWithTrackableLinks'
import { getSeoZoneCtaTrackingConfig } from '../utils/TrackingConfigs'
import { useTrackingContext } from '../hooks/TrackingContext'

export const SeoTiles = ({ zoneTiles, textSize = 6, startTrackingPositionForTiles }: any) => {
  const { sourcePagePath } = useTrackingContext()

  if (!Array.isArray(zoneTiles) || !zoneTiles.length) {
    return null
  }

  return (
    <>
      {zoneTiles.map((zoneTile, index) => {
        if (!zoneTile.tileTitle || !zoneTile.tileDescription) return null
        return (
          <Box key={`seo-zone-tile-${index}`} mb={index !== zoneTiles.length - '7' ? '7' : '0'}>
            {zoneTile.tileTitle && (
              <H3 fontSize={textSize} fontWeight='bold'>
                {zoneTile.tileTitle}
              </H3>
            )}
            {zoneTile.tileDescription && (
              <Typography fontSize={textSize}>
                <MarkdownWithTrackableLinks
                  html={zoneTile.tileDescription}
                  getTrackingInfo={(destinationPagePath, ctaValue) =>
                    getSeoZoneCtaTrackingConfig(sourcePagePath, destinationPagePath, ctaValue, index + startTrackingPositionForTiles)}
                />
              </Typography>
            )}
          </Box>
        )
      })}
    </>
  )
}
